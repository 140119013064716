(() => {
  const contactBadgeButton = document.querySelector('.js-contact-badge-button-open');
  const contactBadge = document.querySelector('.js-contact-badge');
  if (contactBadgeButton && contactBadge) {
    contactBadgeButton.addEventListener('click', () => {
      contactBadgeButton.classList.add('is-open');
      contactBadge.classList.add('contact-badge-is-open');
    });

    document
      .querySelector('.js-contact-badge-button-close')
      .addEventListener('click', () => {
        contactBadge.classList.remove('contact-badge-is-open');
        contactBadgeButton.classList.remove('is-open');
      });
  }
})();
