import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';

import './lib/lazysizes';
import './components/contact-badge';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(VueRouter);

Vue.config.productionTip = false;

const BlogListElement = document.querySelector('.js-blog-listing');
if (BlogListElement) {
  const router = new VueRouter();
  const BlogListing = () => import('@/components/blog/BlogListing');
  // eslint-disable-next-line no-new
  new Vue({
    router,
    render: h => h(BlogListing, {
      props: { ...BlogListElement.dataset },
    }),
    el: BlogListElement,
  });
}

/**
 * CANVAS THEME
 */

/* eslint-disable no-unused-vars, no-eval */
// eslint-disable-next-line max-len
const canvasJQ = () => import(/* webpackChunkName: 'can-vendor' */ '!!raw-loader!./canvas-theme/js/jquery');
const canvasPlug = () => import(/* webpackChunkName: 'can-vendor' */ '!!raw-loader!./canvas-theme/js/plugins');
const canvasFnc = () => import(/* webpackChunkName: 'can-vendor' */ '!!raw-loader!./canvas-theme/js/functions');
canvasJQ().then(rawModule => eval.call(null, rawModule.default));
canvasPlug().then(rawModule => eval.call(null, rawModule.default));
canvasFnc().then(rawModule => eval.call(null, rawModule.default));

/* eslint-disable import/first */
const canvasBoot = () => import(/* webpackChunkName: 'can-vendor' */ './canvas-theme/css/bootstrap.css');
const canvasIcns = () => import(/* webpackChunkName: 'can-vendor' */ './canvas-theme/font-icons.css');
const canvasSwiper = () => import(/* webpackChunkName: 'can-vendor' */ './canvas-theme/css/swiper.css');
const canvasMagnific = () => import(/* webpackChunkName: 'can-vendor' */ './canvas-theme/css/magnific-popup.css');

import './canvas-theme/style.scss';
import './mds-sass/mds-styles.scss';
import './assets/mds_logo.svg';
import './assets/mds.pimprint.svg';
import './assets/pimcore_partner_gold_mds.eu.svg';
import './assets/go-digital_logo.svg';
import './assets/abstract-planes.svg';
import './assets/paper-plane.svg';
import './assets/abstract-planes-bright.svg';
import './assets/play-button.svg';
