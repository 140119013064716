import lazySizes from 'lazysizes';
import 'lazysizes/plugins/include/ls.include';

document.addEventListener('orientationchange', lazySizes.autoSizer.checkElems());
lazySizes.init();

window.addEventListener('load', () => {
// update isotope/masonry portfolio or grid items when images load
  const gridContainer = document.querySelector('.grid-container');
  if (gridContainer) {
    // eslint-disable-next-line no-undef
    const iso = Isotope.data(gridContainer);
    if (iso) {
      document.addEventListener('lazyloaded', () => {
        iso.layout();
      });
    }
  }
});
